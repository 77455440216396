import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import Sidepanel from "../../components/sidepanel";
import { post } from "../../Api";
import axios from "axios";
import bg from "../../images/mainbg1.jpg";
import { BaseUrl } from "../../utils/base_url";

import { BrowserRouter as Router, Route, useParams } from "react-router-dom";

function ViewDelivery() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});

  const getData = async (data, i) => {
    try {
      const res = await axios.get(`${BaseUrl}/order/${id}`);
      console.log(res);
      if (Array.isArray(res.data.data)) {
        setData(res.data.data[0]);
        console.log(data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleBackClick = () => {
    navigate("/orders");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="main-body h-screen w-full bg-slate-100">
      <img
        src={bg}
        alt=""
        srcset=""
        className="object-cover w-[100%] h-[100%] fixed"
      />
      <div className="main-body-container w-full flex flex-row absolute">
        <Sidepanel />
        <div className="w-5/6 side-panel p-5 md:ml-[300px] ml-16">
          <div className="common-body p-5 flex flex-col h-full items-center ">
            <div className="form-body md:w-[80%] w-full flex flex-col p-5 mx-auto items-center justify-center bg-white rounded-lg ">
              <h1 className="flex items-center justify-center pt-4 text-xl uppercase font-bold pb-4">
                View Delivery
              </h1>
              <form className="flex flex-col w-full">
                <label
                  htmlFor="customerId"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Customer ID
                </label>
                <input
                  value={data.customer_id}
                  type="text"
                  id="customerId"
                  name="customerId"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />

                <label
                  htmlFor="orderId"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Order ID
                </label>
                <input
                  value={data.order_id}
                  type="text"
                  id="orderId"
                  name="orderId"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />

                <label
                  htmlFor="pickup"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Pickup
                </label>
                <input
                  value={data.branch_pickup}
                  type="text"
                  id="pickup"
                  name="pickup"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />

                <label
                  htmlFor="destination"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Destination
                </label>
                <input
                  value={data.destination}
                  type="text"
                  id="destination"
                  name="destination"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />

                <label
                  htmlFor="date"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Estimated Date
                </label>
                <input
                  value={data.estimated_date}
                  type="date"
                  id="date"
                  name="date"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />

                <label
                  htmlFor="departureDate"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Departure Date
                </label>
                <input
                  value={data.departure_date}
                  type="date"
                  id="departureDate"
                  name="departureDate"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />

                <label
                  htmlFor="tp"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Telephone number
                </label>
                <input
                  value={data.telephone_number}
                  type="text"
                  id="tp"
                  name="tp"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />

                <label
                  htmlFor="vehicle"
                  className="mb-2 font-semibold text-gray-600"
                >
                  Vehicle
                </label>
                <input
                  value={data.vehicle}
                  type="text"
                  id="vehicle"
                  name="vehicle"
                  className="mb-4 p-2 rounded-lg border border-gray-300"
                  disabled
                />
                <button
                  onClick={handleBackClick}
                  className="py-2 px-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-lg w-52 ml-auto mt-8"
                >
                  Back
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDelivery;
